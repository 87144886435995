import { graphql } from 'gatsby';
import React from 'react';
import { adaptParagraphs } from '@src/adapters/paragraph';
import Paragraph from '@src/components/paragraph';
import LayoutDefault from '@src/layouts/default';
import { ApproachQuery } from '@types';
import styles from './mon-approche.module.scss';
import ContactForm from '@src/components/contact-form';

interface ApproachProps {
  data: ApproachQuery;
}

const ApproachPage: React.FC<ApproachProps> = ({ data }) => {
  const paragraphsData = adaptParagraphs(data.paragraphs);
  const visionData = paragraphsData.find(e => e.id == 'vision');
  const deroulementData = paragraphsData.find(e => e.id == 'deroulement');
  const consultationData = paragraphsData.find(e => e.id == 'consultation');

  return (
    <LayoutDefault pageId="approche">
      <div className={styles.page}>
        <div className={styles.container}>
          {visionData && (
            <div className={styles.vision}>
              <div className={styles.content}>
                <Paragraph paragraph={visionData} />
              </div>
            </div>
          )}

          {deroulementData && (
            <div className={styles.deroulement}>
              <div className={styles.content}>
                <Paragraph paragraph={deroulementData} hLevel={2} />
              </div>
            </div>
          )}

          {consultationData && (
            <div className={styles.consultation}>
              <div className={styles.content}>
                <Paragraph paragraph={consultationData} hLevel={2} />
              </div>
            </div>
          )}
        </div>

        <ContactForm />
      </div>
    </LayoutDefault>
  );
};

export const query = graphql`
  query Approach {
    paragraphs: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "paragraph" } }
        frontmatter: { id: { in: ["vision", "deroulement", "consultation"] } }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            centered
            image {
              childImageSharp {
                fluid(maxWidth: 330, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            image_right
          }
        }
      }
    }
  }
`;

export default ApproachPage;
